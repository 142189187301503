import React, { useState } from 'react';
import styled from '@emotion/styled';

import { Divider } from '../../../components/Divider';
import { CategoriesDropdown } from './components/CategoriesDropdown';
import { TemplatesList } from '../../home/who-should-use-section/components/TemplatesList';

import { colors } from '../../../theme/colors';
import { dimensions } from '../../../theme/media';

import saasIcon from '../../home/who-should-use-section/assets/saas-icon.svg';
import airBnbIcon from '../../home/who-should-use-section/assets/airbnb-icon.svg';
import wealthIcon from '../../home/who-should-use-section/assets/wealth-icon.svg';

const Container = styled.div`
  background-color: ${colors.grey};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Header = styled.h1`
  font-family: 'Lato';
  font-size: 3.7rem;
  letter-spacing: 0.85rem;
  font-weight: 100;
  margin-bottom: 45px;
  text-align: center;
  color: ${colors.landingWhite};
  ${dimensions.tablet} {
    font-size: 2.8rem;
    letter-spacing: 0.7rem;
    line-height: 3.2rem;
  }
  ${dimensions.mobile} {
    font-size: 2rem;
    letter-spacing: 0.5rem;
    line-height: 2.2rem;
  }
`;

const TemplatesContainer = styled.div`
  background-color: ${colors.landingGrey};
  min-height: calc(100vh - 400px);
`;

const categoriesOptions = [
  'All templates',
];

const templates = [{
  icon: saasIcon,
  description: 'SAAS',
  templateId: '0',
}, {
  icon: airBnbIcon,
  description: 'AirBnB',
  templateId: '1',
}, {
  icon: wealthIcon,
  description: 'Wealth Calculator',
  templateId: '2',
}];

export const CategorySelector = () => {
  const [selectedOption, setSelectedOption] = useState('');
  return (
    <Container>
      <Content>
        <Divider height={120} />
        <Header>All templates</Header>
        <Divider height={60} />
      </Content>
      <CategoriesDropdown
        options={categoriesOptions}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      />
      <Divider height={30} />
      <TemplatesContainer>
        <Divider height={100} />
        <TemplatesList templates={templates} />
      </TemplatesContainer>
    </Container>
  );
};
