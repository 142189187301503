import React from 'react';

import { Header } from '../Header';
import { CategorySelector } from '../../sections/templates/category-selector/CategorySelector';
import { Footer } from '../Footer';

import { colors } from '../../theme/colors';

const TemplatesPage = ({ location }) => {
  return (
    <div>
      <Header location={location} />
      <CategorySelector />
      <Footer backgroundColor={colors.landingGrey} />
    </div>
  );
};

export default TemplatesPage
