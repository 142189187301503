import React from 'react';
import styled from '@emotion/styled';

import { SmallParagraph } from '../../../../components/SmallParagraph';

import { colors } from '../../../../theme/colors';

import arrowDownIcon from '../assets/arrow-down.svg';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1140px;
  margin: 0 auto;
`;

const SelectedOption = styled.div`
  display: flex;
  flex-direction: row;  
  align-items: center;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  padding: 0 15px;
  &:hover {
    background-color: #545269;
  }
`;

const SelectedOptionLabel = styled.div`

`;

const Image = styled.img`
  width: 18px;
  margin: 0 0 0 20px;
`;

export const CategoriesDropdown = ({ options, selectedOption, setSelectedOption }) => {
  return (
    <Container>
      <SelectedOption>
        <SelectedOptionLabel>
          <SmallParagraph color={colors.landingWhite}>
            {selectedOption === '' ? 'Category' : selectedOption}
          </SmallParagraph>
        </SelectedOptionLabel>
        <Image src={arrowDownIcon} />
      </SelectedOption>
    </Container>
  );
};
