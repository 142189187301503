import React, { useEffect, useState } from 'react'
import { Router } from '@reach/router'
import LogRocket from 'logrocket';
import Amplify from 'aws-amplify'

import CareersUpPage from '../components/pages/careers'
import HomePage from '../components/pages/home'
import ComingSoonPage from '../components/pages/coming-soon'
import PartnerUpPage from '../components/pages/partner-up'
import PrivacyPolicy from '../components/pages/privacy-policy'
import TermsAndConditions from '../components/pages/terms-and-conditions'
import TemplatesPage from '../components/pages/templates';

import config from '../aws-exports'
import PublicRoute from '../components/routes/PublicRoute'

const App = () => {
  const [hasMounted, setHasMounted] = useState(false);

  Amplify.configure(config);
  LogRocket.init('ymwd3h/landingpage-zovsm');

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <Router>
      <PublicRoute path="/" component={HomePage} />
      <PublicRoute path="/templates" component={TemplatesPage} />
      <PublicRoute path="/careers" component={CareersUpPage} />
      <PublicRoute path="/coming-soon" component={ComingSoonPage} />
      <PublicRoute path="/partner-up" component={PartnerUpPage} />
      <PublicRoute path="/privacy-policy" component={PrivacyPolicy} />
      <PublicRoute path="/terms-and-conditions" component={TermsAndConditions} />
    </Router>
  )
};

export default App
